<template>
  <div class="flex h-full flex-col">
    <div class="flex justify-center bg-black p-4">
      <img src="/assets/logo.png" alt="Logo" />
    </div>
    <div class="flex h-full justify-center md:p-10">
      <div class="w-full md:w-2/3 md:border lg:w-1/2 xl:w-1/3">
        <Error v-if="error" :error="error" @reset="reset()" />
        <PromoItem v-else-if="quiz && success" :quiz="quiz" />
        <Loading v-else-if="loading" />
        <Start
          v-else-if="quiz && status === 'start'"
          :quiz="quiz"
          @start="status = 'quiz'"
        />
        <Quiz
          v-else-if="quiz && status === 'quiz'"
          :questions="questions"
          @complete="status = 'complete'"
        />
        <End v-else-if="quiz && status === 'complete'" :questions="questions" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import End from "./components/End.vue";
import Error from "./components/Error.vue";
import Loading from "./components/Loading.vue";
import PromoItem from "./components/PromoItem.vue";
import Quiz from "./components/Quiz.vue";
import Start from "./components/Start.vue";
import { useStore } from "./composables/store";

const { error, getQuiz, loading, quiz, resetState, success } = useStore();
const status = ref("start");

const questions = ref([]);

const reset = async () => {
  status.value = "start";
  resetState();
  await getQuiz(1);
};

watch(
  () => quiz.value,
  (value) => {
    if (value) {
      const json = value.questionJson;
      questions.value = typeof json === "string" ? JSON.parse(json) : json;
    }
  }
);

onMounted(async () => {
  await getQuiz(1);
});
</script>
