<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <img
        :src="`https://storage.googleapis.com/ladec-yritysvisa.appspot.com/${quiz.promoImage}`"
        alt="Promo image"
        class="h-[40vh] w-full object-cover"
      />
      <div class="px-3 py-3 text-center">
        <h1>{{ quiz.promoTitle }}</h1>
        <span v-html="decodeEntities(quiz.promoContent)"></span>
      </div>
    </div>
    <div class="mb-5 py-5 text-center">
      <Button label="Tutustu" @click="openPromoUrl(quiz.promoUrl)" />
    </div>
  </div>
</template>

<script setup>
import Button from "./Button.vue";

const props = defineProps({
  quiz: Object,
});

const decodeEntities = (str) => {
  const el = document.createElement("textarea");
  el.innerHTML = str;
  return el.value;
};

const openPromoUrl = (url) => {
  window.open(url, "_blank");
};
</script>
