export function useValidator() {
  const validators = {
    isPhoneNumber: async (value) => {
      return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        value
      );
    },

    isRequired: async (value) => {
      return value && value.length;
    },
  };

  const validate = async (rules, value) => {
    for (const rule of rules) {
      const valid = await validators[rule](value);
      if (!valid) return rule;
    }
  };

  return {
    validate,
  };
}
