import { db, functions } from "@/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { reactive, toRefs } from "vue";

const getDefaultState = () => {
  return {
    error: null,
    loading: false,
    quiz: null,
    points: 0,
    success: null,
  };
};

const state = reactive(getDefaultState());

export function useStore() {
  const getQuiz = async (type) => {
    try {
      state.loading = true;
      const q = query(
        collection(db, "quizzes"),
        where("active", "==", true),
        where("type", "==", type)
      );
      const querySnapshot = await getDocs(q);
      const quizzes = querySnapshot.docs.map((doc) => {
        const quiz = doc.data();
        quiz.id = doc.id;
        return quiz;
      });
      if (!quizzes.length) {
        throw { code: "not-found", message: "Ei aktiivista visaa saatavilla." };
      }
      state.quiz = quizzes[0];
      state.loading = false;
    } catch (err) {
      state.error = err;
      state.loading = false;
    }
  };

  const participate = async (data) => {
    try {
      state.loading = true;
      data.quizId = state.quiz.id;
      const participate = httpsCallable(functions, "participate");
      const res = await participate(data);

      if (!res.data.success) throw res.data;
      state.loading = false;
      state.success = true;
    } catch (err) {
      state.error = err;
      state.loading = false;
    }
  };

  const pointIncrease = () => {
    state.points++;
  };

  const resetState = () => {
    Object.assign(state, getDefaultState());
  };

  return {
    ...toRefs(state),
    getQuiz,
    participate,
    pointIncrease,
    resetState,
  };
}
