<template>
  <div v-if="result" class="flex h-full flex-col justify-between">
    <div class="px-8 py-4">
      <div v-if="result.correct" class="flex items-center gap-2 py-6">
        <CheckCircleIcon class="h-8 w-8 text-green-600" />
        <h3>Vastasit oikein!</h3>
      </div>
      <div v-else class="flex items-center gap-2 py-6">
        <ExclamationCircleIcon class="h-8 w-8 text-rose-600" />
        <h3>Vastasit väärin!</h3>
      </div>
      <h2 v-if="questions.length > 1">
        {{ `Kysymys ${questionIndex + 1}/${questions.length}` }}
      </h2>
      <div class="py-2">
        <p>{{ currentQuestion.question }}</p>
      </div>
      <div class="py-2">
        <label>Oikea vastaus:</label>
        <ul class="list-disc px-8">
          <li v-for="answer in result.answers" :key="answer.value">
            {{ answer.text }}
          </li>
        </ul>
      </div>
    </div>
    <div class="mb-5 py-5 text-center">
      <Button
        :label="
          questionIndex < questions.length - 1 ? 'Jatka' : 'Osallistu arvontaan'
        "
        @click="next()"
      />
    </div>
  </div>
  <div v-else>
    <div class="px-8 py-4">
      <h2 v-if="questions.length > 1">
        {{ `Kysymys ${questionIndex + 1}/${questions.length}` }}
      </h2>
      <div class="py-4">
        <p>{{ currentQuestion.question }}</p>
      </div>
      <div class="grid gap-y-2 pt-4">
        <Button
          v-for="option in currentQuestion.options"
          :key="option.value"
          :label="option.text"
          :active="selectedOptions.includes(option.value)"
          @click="selectOption(option.value, currentQuestion.answers)"
        />
      </div>
      <div v-if="currentQuestion.answers.length > 1" class="mt-4 text-center">
        <Button
          :disabled="!selectedOptions.length"
          label="Vastaa"
          @click="answer(selectedOptions, currentQuestion.answers)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/composables/store";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import Button from "./Button.vue";

const props = defineProps({
  questions: Array,
});

const emit = defineEmits(["complete"]);

const { pointIncrease } = useStore();

const questionIndex = ref(0);
const currentQuestion = computed(() => props.questions[questionIndex.value]);
const result = ref(null);

const selectedOptions = ref([]);

const answer = (value, answers) => {
  if (value.length) {
    if (
      answers.every((answer) => value.includes(parseInt(answer))) &&
      value.length === answers.length
    ) {
      pointIncrease();
      result.value = {
        answers: currentQuestion.value.options.filter((option) =>
          value.includes(option.value)
        ),
        correct: true,
      };
    } else {
      result.value = {
        answers: currentQuestion.value.options.filter(
          (option) =>
            answers.includes(option.value) ||
            answers.includes(option.value.toString())
        ),
        correct: false,
      };
    }
  } else if (answers.some((answer) => value === parseInt(answer))) {
    pointIncrease();
    result.value = {
      answers: [
        currentQuestion.value.options.find((option) => option.value === value),
      ],
      correct: true,
    };
  } else {
    result.value = {
      answers: currentQuestion.value.options.filter(
        (option) =>
          answers.includes(option.value) ||
          answers.includes(option.value.toString())
      ),
      correct: false,
    };
  }
};

const next = () => {
  if (questionIndex.value < props.questions.length - 1) {
    questionIndex.value++;
  } else {
    emit("complete");
  }
  result.value = null;
};

const selectOption = (value, answers) => {
  if (answers.length === 1) {
    answer(value, answers);
  } else {
    const index = selectedOptions.value.indexOf(value);
    if (index != -1) {
      selectedOptions.value.splice(index, 1);
    } else {
      selectedOptions.value.push(value);
    }
  }
};
</script>
