import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBuhVNfXNH7bQ7qpdm6MjHg9GiwpgnUuYU",
  authDomain: "ladec-yritysvisa.firebaseapp.com",
  projectId: "ladec-yritysvisa",
  storageBucket: "ladec-yritysvisa.appspot.com",
  messagingSenderId: "575468500562",
  appId: "1:575468500562:web:9ba18288cfb0f9f0df518b",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const functions = getFunctions(app, "europe-west3");

export { db, functions };
