<template>
  <div class="h-full px-8 py-4">
    <!--<h2>{{ `Tulos: ${points}/${questions.length}` }}</h2>-->
    <form @submit.prevent="handleSubmit()" class="flex h-full flex-col py-4">
      <h3>Osallistu arvontaan:</h3>
      <div class="grid gap-y-2 py-4">
        <Input
          :error="state.nickname.error"
          label="Nimimerkki"
          placeholder="Nimimerkki"
          type="text"
          v-model="state.nickname.value"
        />
        <Input
          :error="state.phone.error"
          label="Puhelinnumero"
          placeholder="Puhelinnumero"
          type="tel"
          v-model="state.phone.value"
        />
      </div>
      <div class="flex grow flex-col items-center justify-between py-5">
        <Button label="Osallistu" type="submit" />
        <div class="pt-8">
          <a
            class="underline"
            href="https://www.ladec.fi/bisnesvisa-saannot-tietosuoja"
            target="_blank"
            >Säännöt & tietosuoja</a
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { useStore } from "@/composables/store";
import { reactive } from "vue";
import { useValidator } from "../composables/validator";
import Button from "./Button.vue";
import Input from "./Input.vue";

const props = defineProps({
  questions: Array,
});

const { participate, points } = useStore();

const state = reactive({
  nickname: {
    error: "",
    rules: ["isRequired"],
    value: "",
  },
  phone: {
    error: "",
    rules: ["isRequired", "isPhoneNumber"],
    value: null,
  },
});

const { validate } = useValidator();

const isFormValid = async () => {
  let isValid = true;
  for (const [_, value] of Object.entries(state)) {
    value.error = await validate(value.rules, value.value);
    if (value.error) isValid = false;
  }
  return isValid;
};

const handleSubmit = async () => {
  const isValid = await isFormValid();
  if (!isValid) return;
  const data = {};
  for (const [key, value] of Object.entries(state)) {
    data[key] = value.value;
  }

  participate(data);
};
</script>
